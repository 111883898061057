import Compressor from 'compressorjs';

export default function compressImgs(files) {
  return Promise.all(
    files.map(
      (file) =>
        new Promise((resolve, reject) => {
          // eslint-disable-next-line
          new Compressor(file, {
            success: (blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
              };
            },
            error: reject,
            quality: 0.6,
            maxWidth: 300,
            maxHeight: 300,
          });
        }),
    ),
  );
}