import React, { Component } from 'react';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import LoadingCircle from './LoadingCircle';
import { addQuestions, addCsel } from '../redux/actions';
import Question from './Question';
import PreQuestions from './PreQuestions';

const pageNums = [1, 3, 6, 10, 15, 21];

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    Papa.parse(`${process.env.PUBLIC_URL}/csv/report.csv`, {
      header: true,
      delimiter: ';',
      download: true,
      skipEmptyLines: true,
      complete: d => {
        let countMain = 0;
        let data = d.data.map(x => {
          if (x.isMain === '1') {
            countMain++;
          }
          return { ...x, countMain };
        });

        for (let i = 0; i < data.length - 1; i++) {
          if (data[i + 1].countMain > data[i].countMain) {
            data[i].isLast = true;
          }
        }
        data[data.length - 1].isLast = true;
        const cselArr = [];
        data.forEach(x => {
          if (x.csel) {
            cselArr.push([x.csel, x.countMain]);
          }
        });
        data = data.reduce((acc, obj) => {
          const key = obj.countMain;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
        if (cselArr.length) this.parseCsv(cselArr);
        this.updateData(data, cselArr.length);
      },
    });
  }

  updateData = (data, stillLoading) => {
    this.props.addQuestions({ data });
    if (!stillLoading) this.setState({ loading: false });
  };

  parseCsv = arr => {
    const loadingArr = arr.map(() => true);
    arr.forEach((x, i) => {
      Papa.parse(`${process.env.PUBLIC_URL}/csv/${x[0]}.csv`, {
        header: true,
        delimiter: ';',
        download: true,
        skipEmptyLines: true,
        complete: d => {
          loadingArr[i] = false;
          let cselCountMain = 0;
          const data = d.data.map(e => {
            if (e.isMain === '1') {
              cselCountMain++;
            }
            return { ...e, cselCountMain };
          });
          this.updateCsel(data, x[1], loadingArr);
        },
      });
    });
  };

  updateCsel = (data, countMain, loadingArr) => {
    this.props.addCsel({ countMain, data });
    if (loadingArr.every(x => x === false)) this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) {
      return <LoadingCircle />;
    }
    const { page, questions } = this.props;
    const from = pageNums[page - 1];
    const to = pageNums[page];
    const dataPaged = Object.keys(questions)
      .filter(x => x >= from && x < to)
      .map(key => questions[key]);
    return (
      <div>
        {page === 1 && <PreQuestions />}
        {dataPaged.map(x =>
          x.map((item, i) => (
            <Question
              key={`${item.countMain}-${i}`}
              question={item.name}
              id={i}
              main={item.isMain === '1'}
              countMain={item.countMain}
              isLast={item.isLast}
            />
          ))
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const questions = state.questions.questions;
  return { questions };
};

export default connect(
  mapStateToProps,
  { addQuestions, addCsel }
)(Form);
