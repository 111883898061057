import { combineReducers } from 'redux';
import changeActive from './changeActive';
import changeComment from './changeComment';
import images from './images';
import questions from './questions';
import data from './data';
import mainHide from './mainHide';

export default combineReducers({
  changeActive,
  changeComment,
  images,
  questions,
  data,
  mainHide,
});
