import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Form from './Components/Form';
import generateDoc from './Misc/generateDoc';
import logo from './Assets/logo';
import headerLogo from './Assets/img/SATTVA.png';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#320670',
    },
    secondary: {
      main: '#edb417',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = theme => ({
  container: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing.unit,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  pagination: {
    padding: theme.spacing.unit,
    paddingTop: 3 * theme.spacing.unit,
  },
  pagButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomPagButtons: {
    padding: theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
    display: 'flex',
    justifyContent: 'space-between',
  },
  step: {
    paddingRight: 0,
  },
  touchRipple: {
    color: 'rgba(0, 0, 0, 0.15)',
    left: '-4px',
    borderRadius: '4px',
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    width: '40px',
    marginRight: '10px',
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      downloadInProgress: false,
    };
  }

  handlePageInc = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  handlePageIncBottom = () => {
    window.scrollTo(0, 0);
    this.handlePageInc();
  };

  handlePageDec = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  handlePageDecBottom = () => {
    window.scrollTo(0, 0);
    this.handlePageDec();
  };

  handleStep = step => {
    this.setState({ page: step });
  };

  handleDownload = () => {
    this.setState({ downloadInProgress: true });
    const {
      questions,
      active,
      cselTervs,
      comments,
      imgsrcs,
      data,
    } = this.props;
    if (Object.keys(active).length) {
      const docDefinition = generateDoc(
        questions,
        active,
        cselTervs,
        comments,
        imgsrcs,
        logo,
        data
      );
      console.log(docDefinition);
      pdfMake
        .createPdf(docDefinition)
        .download(
          `${data.telephely || 'Report'}_${new Date()
            .toISOString()
            .slice(0, 10)}.pdf`,
          () => {
            this.setState({ downloadInProgress: false });
          }
        );
    }
  };

  render() {
    const { classes } = this.props;
    const steps = ['', '', '', '', ''];
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <div className={classes.brand}>
              <img src={headerLogo} alt="logo" className={classes.headerLogo} />
              <Typography variant="h6" color="inherit">
                Sattva Riport
              </Typography>
            </div>
            {this.state.downloadInProgress ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <Button color="inherit" onClick={this.handleDownload}>
                Letöltés
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <div className={classes.pagination}>
            <Stepper
              nonLinear
              activeStep={this.state.page - 1}
              classes={{
                root: classes.stepper,
              }}
            >
              {steps.map((label, index) => {
                return (
                  <Step
                    key={index}
                    classes={{
                      root: classes.step,
                    }}
                  >
                    <StepButton
                      onClick={this.handleStep.bind(this, index + 1)}
                      completed={false}
                      classes={{
                        touchRipple: classes.touchRipple,
                      }}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div className={classes.pagButtons}>
              {this.state.page === 1 ? (
                <div />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handlePageDec}
                >
                  Előző
                </Button>
              )}
              {this.state.page === 5 ? (
                <div />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handlePageInc}
                >
                  Következő
                </Button>
              )}
            </div>
          </div>
          <Form page={this.state.page} />
          <div className={classes.bottomPagButtons}>
            {this.state.page === 1 ? (
              <div />
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handlePageDecBottom}
              >
                Előző
              </Button>
            )}
            {this.state.page === 5 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleDownload}
              >
                Letöltés
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handlePageIncBottom}
              >
                Következő
              </Button>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  const active = state.changeActive.active;
  const imgsrcs = state.images.imgsArrs;
  const comments = state.changeComment.comments;
  const questions = state.questions.questions;
  const cselTervs = state.questions.cselTervs;
  const data = state.data;
  return { active, imgsrcs, comments, questions, cselTervs, data };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(App));
