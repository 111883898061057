import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import ResponsiveDialog from './ResponsiveDialog';
import LoadingCircle from './LoadingCircle';
import {
  changeActive,
  changeComment,
  addImages,
  delImgs,
  changeHide,
} from '../redux/actions';
import compressImgs from '../Misc/compressImgs';

const styles = theme => ({
  divDouble: {
    borderTop: '3px double rgba(0, 0, 0, 0.12)',
    backgroundColor: 'transparent',
  },
  button: {
    margin: theme.spacing.unit,
    display: 'block',
    flex: 1,
  },
  buttonRed: {
    color: theme.palette.getContrastText('#e83535'),
    backgroundColor: '#e83535',
    '&:hover': {
      backgroundColor: '#b22929',
    },
  },
  buttonGrey: {
    color: theme.palette.getContrastText('#6d6d6d'),
    backgroundColor: '#6d6d6d',
    '&:hover': {
      backgroundColor: '#3a3a3a',
    },
  },
  root: {
    width: '100%',
    padding: theme.spacing.unit,
  },
  question: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    //    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainQuestion: {
    marginTop: theme.spacing.unit,
    marginBottom: 0,
  },
  main: {
    fontWeight: 'bold',
    fontSize: '1.875rem',
  },
  answers: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  exp: {
    margin: theme.spacing.unit,
    minHeight: 36,
  },
  expSum: {
    minHeight: '36px !important',
    borderBottom: '1px solid #f0f0f0',
    backgroundColor: '#f7f7f7',
  },
  details: {
    flexDirection: 'column',
  },
  content: {
    margin: '0 !important',
  },
  InputRoot: {
    padding: '11px 14px',
  },
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    marginTop: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  imgContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: '10px',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '5px',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: '2px',
    },
  },
  imgBox: {
    width: '100%',
    height: '180px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '140px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100px',
    },
    '&:hover .delImgIcon': {
      top: 0,
    },
    '&:focus .delImgIcon': {
      top: 0,
    },
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  delImgIcon: {
    position: 'absolute',
    top: -60,
    right: 0,
    transition: '250ms',
    backgroundColor: 'rgba(0,0,0,0.1)',
    color: 'rgba(255,255,255,0.9)',
  },
  hide: {
    display: 'none',
  },
  icon: {
    transition: 'transform 150ms linear',
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
  cselCont: {
    margin: theme.spacing.unit,
    marginTop: 5 * theme.spacing.unit,
    marginBottom: 4 * theme.spacing.unit,
  },
});

class Question extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imgLoading: false,
    };
  }

  changeActive = num => {
    const { id, countMain } = this.props;
    this.props.changeActive({ id, num, countMain });
  };

  handleComment = e => {
    console.log(e.target.value);
    const { id, countMain } = this.props;
    const comment = e.target.value;
    this.props.changeComment({ id, comment, countMain });
  };

  handleHide = mainId => {
    this.props.changeHide({ mainId });
  };

  handleImageChange = e => {
    this.setState({ imgLoading: true });
    const { id, countMain } = this.props;
    const files = [...e.target.files];
    // compress
    //   .compress(files, {
    //     quality: 0.6,
    //     maxWidth: 300,
    //     maxHeight: 300,
    //     resize: true,
    //   })
    //   .then(imgs => {
    //     const imgsrcArr = [];
    //     imgsrcArr.push(...imgs.map(x => `${x.prefix}${x.data}`));
    //     this.props.addImages({ id, imgsrcArr, countMain });
    //     this.setState({ imgLoading: false });
    //   });
    compressImgs(files).then(imgs => {
      this.props.addImages({ id, imgsrcArr: imgs, countMain });
      this.setState({ imgLoading: false });
    });
  };

  handleDelImg = index => {
    const { id, countMain } = this.props;
    this.props.delImgs({ id, index, countMain });
  };

  render() {
    const {
      classes,
      question,
      main,
      countMain,
      isLast,
      active,
      comment,
      imgsrc,
      hide,
      cselTerv,
    } = this.props;
    return (
      <div
        className={`${classes.root}${
          !hide[countMain] && !main ? ` ${classes.hide}` : ''
        }`}
      >
        <div
          className={`${classes.question}${
            !hide[countMain] && !main ? ` ${classes.hide}` : ''
          }${main ? ` ${classes.mainQuestion}` : ''}`}
        >
          <Typography
            variant={main ? 'h4' : 'h5'}
            gutterBottom={!main}
            className={main ? classes.main : ''}
          >
            {question}
          </Typography>
          {main ? (
            <IconButton
              aria-label="Hide"
              className={classes.icon}
              onClick={this.handleHide.bind(this, countMain)}
            >
              <ExpandMoreIcon
                fontSize="large"
                classes={{
                  root: `${classes.icon}${
                    !hide[countMain] ? ` ${classes.rotate}` : ''
                  }`,
                }}
              />
            </IconButton>
          ) : (
            <div />
          )}
        </div>
        <div
          className={`${classes.answers}${
            !hide[countMain] || main ? ` ${classes.hide}` : ''
          }`}
        >
          <Button
            variant="contained"
            color={active === 1 ? 'secondary' : 'default'}
            className={classes.button}
            onClick={this.changeActive.bind(this, 1)}
          >
            Megfelelő
          </Button>
          <Button
            variant="contained"
            color="default"
            className={`${classes.button}${
              active === 2 ? ` ${classes.buttonRed}` : ''
            }`}
            onClick={this.changeActive.bind(this, 2)}
          >
            Nem megfelelő
          </Button>
          <Button
            variant="contained"
            color="default"
            className={`${classes.button}${
              active === 3 ? ` ${classes.buttonGrey}` : ''
            }`}
            onClick={this.changeActive.bind(this, 3)}
          >
            Nem vonatkozik
          </Button>
        </div>
        <ExpansionPanel
          className={`${classes.exp}${
            !hide[countMain] || main ? ` ${classes.hide}` : ''
          }`}
          defaultExpanded={!!(imgsrc || comment)}
        >
          <ExpansionPanelSummary
            className={classes.expSum}
            classes={{
              root: classes.expSum,
              content: classes.content,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.heading}>
              Megjegyzés és kép hozzáadása
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <TextField
              id={`outlined-textarea-${this.props.countMain}-${this.props.id}`}
              label="Megjegyzés"
              placeholder="Megjegyzés"
              defaultValue={comment}
              multiline
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.InputRoot,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.InputLabelRoot,
                },
              }}
              onBlur={this.handleComment}
            />
            <input
              accept="image/*"
              className={classes.input}
              id={`contained-button-file-${this.props.countMain}-${
                this.props.id
              }`}
              multiple
              type="file"
              onChange={this.handleImageChange}
            />
            <label
              htmlFor={`contained-button-file-${this.props.countMain}-${
                this.props.id
              }`}
            >
              <Button
                variant="contained"
                component="span"
                className={classes.uploadButton}
                fullWidth
              >
                <AddAPhotoIcon className={classes.leftIcon} />
                Kép feltöltése
              </Button>
            </label>
            {this.state.imgLoading && <LoadingCircle />}
            <div className={classes.imgContainer}>
              {this.props.imgsrc &&
                this.props.imgsrc.map((x, i) => (
                  <div className={classes.imgBox} key={i} tabIndex="0">
                    <img
                      src={x}
                      alt="preview"
                      id="preview"
                      className={classes.img}
                    />
                    <IconButton
                      aria-label="Close"
                      className={`${classes.delImgIcon} delImgIcon`}
                      onClick={this.handleDelImg.bind(this, i)}
                    >
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  </div>
                ))}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {isLast && cselTerv && (
          <div className={classes.cselCont}>
            <ResponsiveDialog countMain={countMain} cselTerv={cselTerv} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const active = state.changeActive.active[ownProps.countMain]
    ? state.changeActive.active[ownProps.countMain][ownProps.id]
    : false;
  const imgsrc = state.images.imgsArrs[ownProps.countMain]
    ? state.images.imgsArrs[ownProps.countMain][ownProps.id]
    : null;
  const comment = state.changeComment.comments[ownProps.countMain]
    ? state.changeComment.comments[ownProps.countMain][ownProps.id]
    : '';
  const hide = state.mainHide.mainHide;
  const cselTerv = state.questions.cselTervs[ownProps.countMain];
  return { active, imgsrc, comment, hide, cselTerv };
};

export default connect(
  mapStateToProps,
  { changeActive, changeComment, addImages, delImgs, changeHide }
)(withStyles(styles)(Question));
